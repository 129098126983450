import { Component } from "react";
import { TablePabx } from "../../components/Tables/TablePabx/TablePabx";
import { getRows, getRowsPerPage } from '../../components/Tables/TablePabx/propsPabx';
import moment from "moment";
import { Button, Card, CardBody, CardFooter, CardHeader, Collapse, Input, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import { getToken, getUser } from "../../auth/set_services";
import { ClipLoader, PropagateLoader } from "react-spinners";
import axios from "axios";
import { ENDPOINT } from "../../auth/endpoint";
import classnames from 'classnames';
import aqpasso from '../../assets/aqpasso.svg';
import aqpago from '../../assets/aqpago.svg';
import meubus from '../../assets/empresas/meubus_brand.svg';
import aqbank from '../../assets/empresas/AQBank_brand.svg';
import styled from "styled-components";
import { toast } from "react-toastify";
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";


const OptionsSMS = styled.div`
    display: flex;
    margin-bottom: 8px;

    button{
        margin-right: 4px;
    }

    .select-option{
        width: 100%;
    }
`;
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
`
const get_id = () => {
    return window.location.pathname.replace('/pabx-info/', '')
}

const getCall = (id, callback) => {
    var url = ENDPOINT + "telefonia/ligacoes/show-admin/" + id;

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((response) => {
            console.log(response.data)
            var data = response.data;
            callback(data);
        })
}

class PabxDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notas_index: [],
            nota_mensagem: '',
            is_open_nota: false,
            loading_nota: false,
            loading_users_nav: false,

            activeTab: "1",
            status_chamada: '',
            assunto: '',
            descricao: '',
            duracao: '',
            data_hora_inicio_chamada: '',
            contato: null,
            usuarios: null,
            loading_nota_id: false,
            loading_page: true,
            search: '',
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
            error_message: '',
            success_message: '',
        }
    }

    componentDidMount() {
        getCall(get_id(), this.callbackRows.bind(this));
        this.getNotas();
    }
    callbackRows = (stateIs) => {
        this.setState({ ...stateIs.data, loading_page: false })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    onFilter = (filter) => {
        this.setState({ filter });
        //getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    novaNota = () => {
        if (this.state.nota_mensagem.length > 0) {
            this.setState({ loading_nota: true });
            var url = ENDPOINT + "telefonia/ligacoes/notas-store/" + get_id();
            var formData = new FormData();
            formData.append('descricao', this.state.nota_mensagem);
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
                .then(() => {
                    this.setState({ loading_nota: false, is_open_nota: false, nota_mensagem: '' });
                    toast.success('Nota criada com sucesso');
                    this.getNotas(this.state.id);
                })
                .catch((error) => {
                    this.setState({ loading_nota: false });
                    var data = error.response.data;
                    toast.error(data.errors);
                })
        }
    }

    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true })
        //getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        //getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }


    listNavsUsers = () => {
        var listDataUsers = this.listData;

        if(this.state.usuarios != null && this.state.loading_users_nav == false){
            this.setState({
                loading_users_nav: true
            })

            if(this.state.usuarios.AQPago.length > 0){
                var aqpago_menu = {
                    name: <><img src={aqpago} width={20} /> AQPago</>,
                    active: '1-AQPago'
                }

                var check_aqpago = false;

                listDataUsers.forEach(e => {
                    if(e.active == '1-AQPago'){
                        check_aqpago = true;
                    }
                })

                if(check_aqpago == false){
                    listDataUsers.push(aqpago_menu);
                }
            }

            if(this.state.usuarios.AQPasso.length > 0){
                var aqpasso_menu = {
                    name: <><img src={aqpasso} width={20} /> AQPasso</>,
                    active: '1-AQPasso'
                }

                var check_aqpasso= false;
                
                listDataUsers.forEach(e => {
                    if(e.active == '1-AQPasso'){
                        check_aqpasso = true;
                    }
                })

                if(check_aqpasso == false){
                    listDataUsers.push(aqpasso_menu);
                }
            }

            if(this.state.usuarios.MeuBUS.length > 0){
                var meubus_menu = {
                    name: <><img src={meubus} width={20} /> MeuBUS</>,
                    active: '1-MeuBUS'
                }

                var check_meubus= false;
                
                listDataUsers.forEach(e => {
                    if(e.active == '1-MeuBUS'){
                        check_meubus = true;
                    }
                })

                if(check_meubus == false){
                    listDataUsers.push(meubus_menu);
                }
            }

            if(this.state.usuarios.AQBank.length > 0){
                var aqbank_menu = {
                    name: <><img src={aqbank} width={20} /> AQBank</>,
                    active: '1-AQBank'
                }

                var check_aqbank= false;
                
                listDataUsers.forEach(e => {
                    if(e.active == '1-AQBank'){
                        check_aqbank = true;
                    }
                })

                if(check_aqbank == false){
                    listDataUsers.push(aqbank_menu);
                }
            }
        }

        console.log(listDataUsers);
        return listDataUsers;
    }

    listData = [
        { name: 'Dados da chamada', active: '1' },
        { name: 'Notas', active: '3' }
    ];
    Navs = () => {
        return <Nav tabs>
            {this.listNavsUsers().map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }


    getNotas = () => {
        var url = ENDPOINT + "telefonia/ligacoes/notas-list/" + get_id();
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.setState({ notas_index: data })
            })
    }

    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });

        var url = ENDPOINT + "notas/delete/" + id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then(() => {
                this.getNotas(this.state.id);
                toast.success('Deletado com sucesso');
                this.setState({
                    loading_nota_id: null
                });
            })
            .catch((error) => {
                var data = error.response.data;
                toast.error(data.errors);
                this.setState({
                    loading_nota_id: null
                });
            })
    }

    hiddenDelete = (id) => {
        var permissao = getUser().access_system;
        var id_permissao = getUser().id;

        if (permissao === 'super_user'
            || permissao === 'diretoria'
            || permissao === 'supervisor_setor') {
            return true;
        }
        else if (id_permissao === id) {
            return true;
        }

        return false;
    }


    UsersTabPaneDetails = () => (
        <>
            {this.state.usuarios !== null ?
                <>
                    {this.state.usuarios.AQPago.length > 0 ?
                        <TabPane tabId={'1-AQPago'}>
                        <h4>Clientes AQPago encontrados com esse telefone:</h4>
                        <hr/>
                        {this.state.usuarios.AQPago.map((p, key) => (
                                <div>
                                    <>
                                        <b>Nome completo:</b> {p.nome} {p.sobrenome}<br />
                                        <b>Telefone:</b> {p.telefone}<br />
                                        <b>CPF/CNPJ:</b> {p.cpf}<br />
                                        <b>Email:</b> {p.email}<br />
                                        {p.conta.empresa != null ?
                                            <>
                                                <b>Empresa (Nome Fantasia):</b> {p.conta.empresa.nome_fantasia}<br />
                                                <b>CNPJ:</b> {p.conta.empresa.cnpj}
                                            </>
                                            : ''}
                                        <hr />
                                    </>
                                </div>
                        ))}
                    </TabPane>
                    :
                    <></>
                    }
                    {this.state.usuarios.AQPasso.length > 0 ?
                        <TabPane tabId={'1-AQPasso'}>
                        <h4>Clientes AQPasso encontrados com esse telefone:</h4>
                        <hr/>
                            {this.state.usuarios.AQPasso.map((p, key) => (
                                <div>
                                    <>
                                        <b>Nome completo:</b> {p.nome} {p.sobrenome}<br />
                                        <b>Telefone:</b> {p.telefone}<br />
                                        <b>CPF/CNPJ:</b> {p.cpf}<br />
                                        <b>Email:</b> {p.email}<br />
                                        {p.empresa != null ?
                                            <>
                                                <b>Empresa (Nome Fantasia):</b> {p.empresa.nome_fantasia}<br />
                                                <b>CNPJ:</b> {p.empresa.cnpj}<br />
                                                <b>Tipo:</b> {p.empresa.tipo_negocio}
                                            </>
                                            : ''}
                                        <hr />
                                    </>
                                </div>
                            ))}
                        </TabPane>
                    :null}
                    {this.state.usuarios.MeuBUS.length > 0 ?
                        <TabPane tabId={'1-MeuBUS'}>
                        <h4>Clientes MeuBus encontrados com esse telefone:</h4>
                        <hr/>
                            {this.state.usuarios.MeuBUS.map((p, key) => (
                                    <div>
                                        <>
                                            <b>Nome completo:</b> {p.name}<br />
                                            <b>Telefone:</b> {p.phone}<br />
                                            <b>CPF/CNPJ:</b> {p.cpf}<br />
                                            <b>Email:</b> {p.email}<br />
                                            {p.cnpj != null ?
                                                <>
                                                    <b>CNPJ:</b> {p.cnpj}
                                                </>
                                                : ''}
                                            <hr />
                                        </>
                                    </div>
                            ))}
                        </TabPane>
                    :null}
                    {this.state.usuarios.AQBank.length > 0 ?
                        <TabPane tabId={'1-AQBank'}>
                        <h4>Clientes AQBank encontrados com esse telefone:</h4>
                        <hr/>
                            {this.state.usuarios.AQBank.map((p, key) => (
                                <div>
                                    <>
                                        <b>Nome completo:</b> {p.name}<br />
                                        <b>Telefone:</b> {p.phone}<br />
                                        <b>CPF/CNPJ:</b> {p.cpf}<br />
                                        <b>Email:</b> {p.email}<br />
                                        {p.cnpj != null ?
                                            <>
                                                <b>CNPJ:</b> {p.cnpj}
                                            </>
                                            : ''}
                                        <hr />
                                    </>
                                </div>
                            ))}
                        </TabPane>
                    :null}
                    {}
                </>
                : 
                <></>
            }
        </>
    )

    render() {
        return (
            <Div>
                <h3>Pabx - Detalhes da chamada</h3>
                <hr />
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <CardBody>
                        <this.Navs />
                        <br />
                        <div style={{ textAlign: 'center', display: (this.state.loading_page == true ? 'block' : 'none')}}>
                            <PropagateLoader color={'#52c565'} loading={20} size={15} />
                        </div>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId={'1'}>
                                <h4>Detalhes:</h4>
                                <hr />
                                <p>
                                    {this.state.contato !== null ?
                                        <>
                                            <b>Telefone:</b> {this.state.contato.telefone}<br />
                                            <b>Contato:</b> {this.state.contato.nome_completo}<br />
                                        </>
                                        : ''}
                                    <b>Assunto:</b> {this.state.assunto}<br />
                                    <b>Descrição:</b> {this.state.descricao}<br />
                                    <b>Status:</b> {this.state.status_chamada}<br />
                                    <b>Duração:</b> {this.state.duracao}
                                </p>
                            </TabPane>
                            <this.UsersTabPaneDetails />
                            <TabPane tabId={'3'}>
                                <h4>Notas:</h4>
                                <hr />
                                <ul style={{ listStyleType: 'circle' }}>
                                    {this.state.notas_index.map(p => {
                                        return <li key={this.uniqueID} style={{ marginBottom: '7px' }}>
                                            <b>Operador:</b> {p.admin !== null ? p.admin.name : 'Sem Informações'} <br />
                                            <div style={{ border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px' }}>
                                                {p.descricao.split('\n').map((x, i, { length }) => (
                                                    <p style={{ margin: (i + 1 === length ? 0 : 3), fontSize: 14 }}>{x}</p>
                                                ))}
                                            </div>
                                            <small>
                                                {moment(p.created_at).format('DD/MM/YYYY HH:mm')}&nbsp;
                                                <ButtonRed
                                                    hidden={!this.hiddenDelete(p.admin_id)}
                                                    disabled={this.state.loading_nota_id === p.id}
                                                    onClick={() => this.deletarNota(p.id)}>
                                                    Apagar
                                                </ButtonRed>
                                            </small>
                                        </li>
                                    })}
                                </ul>
                                {this.state.notas_index.length === 0 ? <p>Nenhuma nota</p> : ''}
                                <Button
                                    color="success"
                                    onClick={() => this.setState({ is_open_nota: !this.state.is_open_nota })}>
                                    Nova Nota
                                </Button>
                                <Collapse isOpen={this.state.is_open_nota}>
                                    <br />
                                    <Card>
                                        <CardBody>
                                            <Input
                                                name="nota_mensagem"
                                                placeholder="Descrição"
                                                value={this.state.nota_mensagem}
                                                type="textarea"
                                                style={{
                                                    height: '124px',
                                                    resize: 'none'
                                                }}
                                                onChange={this.handleInputChange}
                                            />
                                        </CardBody>
                                        <CardFooter>
                                            <OptionsSMS>
                                                <Button color="danger" onClick={() => this.setState({ is_open_nota: false })}>Cancelar</Button>
                                                <Button color="success" onClick={() => this.novaNota()} disabled={this.state.loading_nota}>
                                                    {this.state.loading_nota === true ?
                                                        <ClipLoader color="#fff" size={15} />
                                                        :
                                                        ''} Criar
                                                </Button>
                                            </OptionsSMS>
                                        </CardFooter>
                                    </Card>
                                </Collapse>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}


export default PabxDetails;